import axios from 'axios';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import { getDateDifferenceInDays } from '../utils/dateDifference';
import { getOrdersCacheObject } from '../utils/getOrdersFromCache';
import { LS_KEYS } from '../utils/localStorageKeys';

import {
  GET_ERRORS,
  SET_LOADING_ORDER_QUERIES_FOR_ADMIN,
  SET_LOADING_ADMIN_STATS,
  SET_LOADING_DISINFECTORS,
  SET_LOADING_OPERATORS,
  SET_LOADING_ADD_MATERIAL_EVENTS,
  LOADING_SORTED_ORDERS_ADMIN,
  LOADING_CUR_MAT,
  LOADING_CLIENTS,
  GET_SORTED_ORDERS_ADMIN,
  GET_ORDER_QUERIES_FOR_ADMIN,
  GET_ADMIN_MONTH_STATS,
  GET_ADMIN_WEEK_STATS,
  GET_ADMIN_DAY_STATS,
  GET_ADV_STATS,
  GET_OPERATOR_STATS,
  GET_ALL_DISINFECTORS_FOR_ADMIN,
  GET_ALL_OPERATORS_FOR_ADMIN,
  GET_ALL_OPERATORS_AND_ADMINS_FOR_ADMIN,
  GET_ADD_MAT_EVENTS_MONTH,
  GET_ADD_MAT_EVENTS_WEEK,
  DISINF_STATS_MONTH_ADMIN,
  DISINF_STATS_MONTH_WEEK,
  DISINF_STATS_DAY_ADMIN,
  ADD_MAT_DISINFECTOR,
  GET_CURR_MAT_ADMIN,
  UPDATE_MAT_COMING,
  MAT_COMING_MONTH,
  MAT_COMING_WEEK,
  SEARCH_CLIENTS,
  CLIENT_BY_ID,
  SET_ORDERS_OF_CLIENT,
  SET_LOADING_CLIENT_ORDERS,
  GET_USER_BY_ID,
  CLEAR_STATS_DATA_ADMIN,
  DELETE_QUERY_FROM_STATE,
  REMOVE_DISABLED_USER_FROM_DOM,
  SET_DATE_IN_CALENDAR,
  UPDATE_GRADE_OF_ORDER,

  SEARCH_ORDERS,
  SET_SEARCH_ORDER_METHOD,
} from './types';


export const getSortedOrders = (date) => async (dispatch) => {
  dispatch(setLoadingSortedOrders());

  const cacheResponse = getOrdersCacheObject();

  try {
    const res = await axios.post('/admin/get-sorted-orders', { date });

    dispatch({
      type: GET_SORTED_ORDERS_ADMIN,
      payload: res.data,
      date: date,
    });

    dispatch({ type: SET_DATE_IN_CALENDAR, payload: date });

    // если кэш записывался более 3 дней назад, то заново получить заказы за +- неделю и записать в кэш
    if (
      !cacheResponse.success ||
      getDateDifferenceInDays(new Date(), new Date(cacheResponse.lastUpdated)) > 3
    ) {

      // console.log('renewing cache');
      // получить заказы +неделя и -неделя от date
      const helper_object = {
        period: 'week',
      };

      const res2 = await axios.post('/order/get-orders-within-period', { object: helper_object });

      const new_cache_object = {
        orders: res2.data.data || [],
        last_updated: new Date(),
      };

      // console.log('res2', res2.data);
      localStorage.setItem(LS_KEYS.ORDERS_IN_CACHE, JSON.stringify(new_cache_object));
    }

  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err, });

    // we have to show orders that belong to date
    // check if 2 dates are the same day
    const orders_in_date = cacheResponse.orders.filter(order => new Date(order.dateFrom).toDateString() === new Date(date).toDateString());

    if (cacheResponse.success) {
      dispatch({
        type: GET_SORTED_ORDERS_ADMIN,
        payload: orders_in_date,
        date: date,
      });

      // close previous toasts
      toast.dismiss();
      toast.warning('Данные получены из кэша');
    }
  }
};


export const getQueriesForAdmin = () => (dispatch) => {
  dispatch(setLoadingQueriesForAdmin());
  axios
    .post('/admin/get-order-queries-for-admin')
    .then((res) =>
      dispatch({
        type: GET_ORDER_QUERIES_FOR_ADMIN,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};


export const adminConfirmsOrderQuery = (object, history) => (dispatch) => {
  // delete query from global state because the query should not stay in queries page
  dispatch(deleteQuery(object.orderId));

  axios.post('/admin/admin-confirms-order-query', { object })
    .then(() => {
      // admin can return order back in SearchOrders component 
      // when admin clicks the button "Отправить обратно" in SearchOrders component, 
      // we will clear the information is that component
      // because the data in the component is not updated immediately
      // we can clear the information by combining 2 dispatches: SEARCH_ORDERS and SET_SEARCH_ORDER_METHOD
      dispatch({ type: SEARCH_ORDERS, payload: [] });
      dispatch({ type: SET_SEARCH_ORDER_METHOD, payload: '', method: '' });

      return history.push('/admin');
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};


// суперадмин ставит оценку на заказ
export const setAdminGradeToOrder = (object, history) => async (dispatch) => {
  try {
    await axios.post('/admin/admin-gives-grade-to-order', { object });

    // update grade of order in redux (admin.stats.orders and in order.orders)
    dispatch({ type: UPDATE_GRADE_OF_ORDER, payload: object });

  } catch (e) {
    const error = {
      info: 'setAdminGradeToOrder',
      message: 'Не удалось поставить оценку за заявку. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const getGenStatsForAdmin = (object) => async (dispatch) => {
  dispatch(loadingStats());

  let dispatch_type = '';

  if (object.type === 'month') {
    dispatch_type = GET_ADMIN_MONTH_STATS;
  }

  if (object.type === 'week') {
    dispatch_type = GET_ADMIN_WEEK_STATS;
  }

  if (object.type === 'day') {
    dispatch_type = GET_ADMIN_DAY_STATS;
  }

  try {
    const res = await axios.post('/stats/for-admin-general', { object });

    dispatch({
      type: dispatch_type,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getGenStatsForAdmin',
      message: 'Не удалось загрузить общую статистику. Попробуйте еще раз',
    };

    toast.error(error.message);

    if (dispatch_type && dispatch_type.length > 0) {
      dispatch({
        type: dispatch_type,
        payload: [],
      });
    }

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const getAdvStats = (object) => async (dispatch) => {
  dispatch(loadingStats());

  try {
    const res = await axios.post('/stats/adv-stats', { object });

    dispatch({
      type: GET_ADV_STATS,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getAdvStats',
      message: 'Не удалось получить статистику рекламы. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_ADV_STATS,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error, });
  }
};


// get operator stats
export const getOperatorStats = (object) => async (dispatch) => {
  dispatch(loadingStats());

  try {
    const res = await axios.post('/stats/operator-stats', { object });

    dispatch({
      type: GET_OPERATOR_STATS,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getOperatorStats',
      message: 'Не удалось получить статистику оператора/админа. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_OPERATOR_STATS,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error, });
  }
};


export const getAllDisinfectorsAndSubadmins = () => async (dispatch) => {
  dispatch(loadingDisinfectors());

  const object = {
    method: 'role',
    roles: ['disinfector', 'subadmin'],
  };

  try {
    const res = await axios.post('/get-users', { object });

    dispatch({
      type: GET_ALL_DISINFECTORS_FOR_ADMIN,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getAllDisinfectorsAndSubadmins',
      message: 'Не удалось получить пользователей. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_ALL_DISINFECTORS_FOR_ADMIN,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const getAllOperators = () => async (dispatch) => {
  dispatch(loadingOperators());

  const object = {
    method: 'role',
    roles: ['operator'],
  };

  try {
    const res = await axios.post('/get-users', { object });

    dispatch({
      type: GET_ALL_OPERATORS_FOR_ADMIN,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getAllOperators',
      message: 'Не удалось получить операторов. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_ALL_OPERATORS_FOR_ADMIN,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const getAllOperatorsAndAmins = () => async (dispatch) => {
  dispatch(loadingOperators());

  const object = {
    method: 'role',
    roles: ['operator', 'admin'],
  };

  try {
    const res = await axios.post('/get-users', { object });

    dispatch({
      type: GET_ALL_OPERATORS_AND_ADMINS_FOR_ADMIN,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getAllOperatorsAndAmins',
      message: 'Не удалось получить пользователей. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_ALL_OPERATORS_AND_ADMINS_FOR_ADMIN,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const addMaterialToDisinfector = (object, occupation, history) => async (dispatch) => {
  try {
    const res = await axios.post('/admin/add-materials-to-disinfector', { object });

    dispatch({
      type: ADD_MAT_DISINFECTOR,
      payload: res.data,
    });

    return history.push(`/${occupation}`);

  } catch (e) {
    const error = {
      info: 'addMaterialToDisinfector',
      message: 'Не удалось добавить материалы. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: ADD_MAT_DISINFECTOR,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const getAddMaterialEvents = (object) => async (dispatch) => {
  dispatch(loadingAddMatEvents());

  let dispatch_type = '';

  try {

    if (object.type === 'month') {
      dispatch_type = GET_ADD_MAT_EVENTS_MONTH;
    }

    if (object.type === 'week') {
      dispatch_type = GET_ADD_MAT_EVENTS_WEEK;
    }

    const res = await axios.post('/admin/get-add-material-events', { object });

    if (dispatch_type && dispatch_type.length > 0) {
      dispatch({
        type: dispatch_type,
        payload: res.data,
      });
    }

  } catch (e) {
    const error = {
      info: 'getAddMaterialEvents',
      message: 'Не удалось получить историю раздач материалов. Попробуйте еще раз',
    };

    if (dispatch_type && dispatch_type.length > 0) {
      dispatch({
        type: dispatch_type,
        payload: [],
      });
    }

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const getDisinfectorStatsForAdmin = (object) => async (dispatch) => {
  dispatch(loadingStats());

  let dispatch_type = '';

  if (object.type === 'month') {
    dispatch_type = DISINF_STATS_MONTH_ADMIN;
  }

  if (object.type === 'week') {
    dispatch_type = DISINF_STATS_MONTH_WEEK;
  }

  if (object.type === 'day') {
    dispatch_type = DISINF_STATS_DAY_ADMIN;
  }

  try {
    const res = await axios.post('/stats/for-admin-disinfector-stats', { object });

    if (dispatch_type && dispatch_type.length > 0) {
      dispatch({
        type: dispatch_type,
        payload: res.data,
      });
    }

  } catch (e) {
    const error = {
      info: 'getDisinfectorStatsForAdmin',
      message: 'Не удалось получить статистику. Попробуйте еще раз',
    };

    const payload = {
      acceptedOrders: [],
      orders: [],
      disinfectorId: object.id,
    };

    if (dispatch_type && dispatch_type.length > 0) {
      dispatch({
        type: dispatch_type,
        payload,
      });
    }

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const getCurrentMaterials = () => async (dispatch) => {
  dispatch(loadingCurMat());

  try {
    const res = await axios.post('/admin/get-current-materials');

    dispatch({
      type: GET_CURR_MAT_ADMIN,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getCurrentMaterials',
      message: 'Не удалось получить материалы на складе. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_CURR_MAT_ADMIN,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


// add material coming
export const addMatComing = (object, history) => async (dispatch) => {
  try {
    const res = await axios.post('/admin/add-mat-coming', { object });

    dispatch({
      type: UPDATE_MAT_COMING,
      payload: res.data,
    });

    history.push('/admin');

  } catch (e) {
    const error = {
      info: 'addMatComing',
      message: 'Не удалось добавить приход материалов. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const getMaterialComingEvents = (object) => async (dispatch) => {
  dispatch(loadingStats());

  let dispatch_type = '';

  if (object.type === 'month') {
    dispatch_type = MAT_COMING_MONTH;
  }

  if (object.type === 'week') {
    dispatch_type = MAT_COMING_WEEK;
  }

  try {
    const res = await axios.post('/admin/get-mat-coming', { object });

    if (dispatch_type && dispatch_type.length > 0) {
      dispatch({
        type: dispatch_type,
        payload: res.data,
      });
    }

  } catch (e) {
    const error = {
      info: 'getMaterialComingEvents',
      message: 'Не удалось получить историю приходов материалов. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: dispatch_type,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const addClient = (object, history, occupation) => async (dispatch) => {
  try {
    await axios.post('/admin/add-client', { object });

    history.push(`/${occupation}`);

  } catch (e) {
    const error = {
      info: 'addClient',
      message: 'Не удалось сохранить клиента. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const editClient = (object, history) => async (dispatch) => {
  try {
    await axios.post('/admin/edit-client', { object });

    history.push(`/client/${object.id}`);

  } catch (e) {
    const error = {
      info: 'editClient',
      message: 'Не удалось отредактировать клиента. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const changeContractNumbers = (object, history) => async (dispatch) => {
  try {
    const res = await axios.post('/admin/change-contract-numbers', { object });

    dispatch({
      type: CLIENT_BY_ID,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'changeContractNumbers',
      message: 'Не удалось отредактировать номер договора клиента. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: CLIENT_BY_ID,
      payload: {
        orders: [],
        contracts: [],
      },
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const searchClients = (object) => async (dispatch) => {
  dispatch(loadingClients(object));

  try {
    const res = await axios.post('/admin/search-clients', { object });

    dispatch({
      type: SEARCH_CLIENTS,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'searchClients',
      message: 'Не удалось получить клиентов. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: SEARCH_CLIENTS,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


// get excel file of corporate clients
export const getCorpClientsExcelFile = () => async (dispatch) => {
  try {
    const res = await axios.get('/admin/corporate-clients-excel', {
      responseType: 'blob',
    });

    fileDownload(res.data, 'clients.xlsx');

  } catch (e) {
    const error = {
      info: 'getCorpClientsExcelFile',
      message: 'Не удалось загрузить файл с клиентами. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const clientById = (id) => async (dispatch) => {
  dispatch(loadingClients({}));

  try {
    const res = await axios.post('/admin/client-by-id', { id });

    dispatch({
      type: CLIENT_BY_ID,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'clientById',
      message: 'Не удалось загрузить клиента. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: CLIENT_BY_ID,
      payload: {
        orders: [],
        contracts: [],
      },
    });

    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};


export const getOrdersOfClient = (object) => async (dispatch) => {
  dispatch({
    type: SET_LOADING_CLIENT_ORDERS,
  });

  try {
    const res = await axios.post('/admin/get-orders-of-client', { object });

    dispatch({
      type: SET_ORDERS_OF_CLIENT,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getOrdersOfClient',
      message: 'Не удалось загрузить заявки клиента. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: SET_ORDERS_OF_CLIENT,
      payload: [],
    });

    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};


export const changePassword = (object, history) => async (dispatch) => {
  try {
    const res = await axios.post('/change-password', { object });

    history.push('/admin/users');

  } catch (e) {
    const error = {
      info: 'changePassword',
      message: 'Не удалось изменить пароль. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const getUserById = (userId) => async (dispatch) => {
  try {
    const res = await sendRequestToGetUser(userId);

    dispatch({
      type: GET_USER_BY_ID,
      payload: res.data,
    });

  } catch (err) {
    // console.log('createOrder catch err =', err.response.data);
    const error = {
      info: 'getUserById',
      message: 'Не удалось загрузить пользователя',
    };

    toast.warn(error.message);

    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};


export const sendRequestToGetUser = async (userId) => {
  return await axios.post('/get-user-by-id', { userId });
}


export const setUserById = (user) => (dispatch) => {
  dispatch({ type: GET_USER_BY_ID, payload: user });
};


export const editUser = (object, history) => async (dispatch) => {
  try {
    await axios.post('/edit-user', { object });

    return history.push('/admin/users')

  } catch (e) {
    const error = {
      info: 'editUser',
      message: 'Не удалось редактировать пользователя. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const disableUser = (object, history) => async (dispatch) => {
  // remove disabled user from Global State
  dispatch({
    type: REMOVE_DISABLED_USER_FROM_DOM,
    payload: object.id,
  });

  try {
    await axios.post('/disable-user', { object });

    return history.push('/admin/users');

  } catch (e) {
    const error = {
      info: 'disableUser',
      message: 'Не удалось удалить пользователя. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const setDisinfectorMaterials = (object, history) => async (dispatch) => {
  try {
    await axios.post('/admin/set-disinfector-materials', { object });

    return history.push('/admin');

  } catch (e) {
    const error = {
      info: 'setDisinfectorMaterials',
      message: 'Не удалось редактировать материалы инспектора. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const setCurrentMaterials = (object, history) => async (dispatch) => {
  try {
    await axios.post('/admin/set-current-materials', { object });

    return history.push('/admin/material-coming');

  } catch (e) {
    const error = {
      info: 'setCurrentMaterials',
      message: 'Не удалось редактировать материалы на складе. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


// delete query from global state
export const deleteQuery = (id) => {
  return {
    type: DELETE_QUERY_FROM_STATE,
    payload: id,
  };
};


export const clearStatsData = () => (dispatch) => {
  return dispatch({
    type: CLEAR_STATS_DATA_ADMIN,
    payload: {},
  });
};


// Loading sorted orders
export const setLoadingSortedOrders = () => {
  return {
    type: LOADING_SORTED_ORDERS_ADMIN,
  };
};


// Loading order queries for admin
export const setLoadingQueriesForAdmin = () => {
  return {
    type: SET_LOADING_ORDER_QUERIES_FOR_ADMIN,
  };
};


// Loading stats for admin
export const loadingStats = () => {
  return {
    type: SET_LOADING_ADMIN_STATS,
  };
};


// Loading disinfectors for admin
export const loadingDisinfectors = () => {
  return {
    type: SET_LOADING_DISINFECTORS,
  };
};


// Loading operators for admin
export const loadingOperators = () => {
  return {
    type: SET_LOADING_OPERATORS,
  };
};


// Loading add material events for admin
export const loadingAddMatEvents = () => {
  return {
    type: SET_LOADING_ADD_MATERIAL_EVENTS,
  };
};


// loading current materials
export const loadingCurMat = () => {
  return {
    type: LOADING_CUR_MAT,
  };
};


export const loadingClients = (object) => {
  if (!object.method) {
    object.method = '';
  }
  if (!object.payload) {
    object.payload = '';
  }
  return {
    type: LOADING_CLIENTS,
    payload: object,
  };
};