import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getSortedOrders } from '../../actions/subadminActions';
import { deleteOrder_v2 } from '../../actions/orderActions';
import orderFullyProcessed from '../../utils/orderFullyProcessed';
import getDateStringElements from '../../utils/dateStringElements';

import RenderOrderInCalendar from '../common/RenderOrderInCalendar';
import ClientNotSatisfiedButton from '../common/ClientNotSatisfiedButton';


class SubadmSortedOrders extends Component {
  state = {
    sortedOrders: this.props.subadmin.sortedOrders,
    colWidth: 6,
  };

  onClick = (hour, date) => {
    const object = getDateStringElements(date);

    const defaultDateString = `${object.year}-${object.month}-${object.day}`;

    let defaultHourString = '';
    if (hour < 10) {
      defaultHourString = `0${hour}:00`;
    } else {
      defaultHourString = `${hour}:00`;
    }

    this.props.history.push('/create-order', {
      pathname: '/create-order',
      state: { hour: defaultHourString, date: defaultDateString }
    });
  };

  // onDeleteOrder = async (id) => {
  //   const object = {
  //     id: id,
  //   };
  //   // console.log('deleteOrder_v2', object);
  //   await this.props.deleteOrder_v2(object, this.props.history, this.props.auth.user.occupation);

  //   this.props.getSortedOrders(this.props.date);
  // }

  duplicateOrder = (order) => {
    this.props.history.push('/create-order', {
      pathname: '/create-order',
      state: {
        orderToDuplicate: order,
      },
    });
  }

  render() {
    let array = [];
    for (let i = 0; i <= 23; i++) {
      array.push({ hour: i, elements: [] });
    }

    this.state.sortedOrders.forEach(order => {
      array[new Date(order.dateFrom).getHours()].elements.push(order);
    });

    let everythingToRender = array.map((object, index) => {

      const renderOrders = object.elements.map((element, i) => (
        <div className={`col-md-${this.state.colWidth} pr-0`} key={i}>
          <div className={`card mt-2 mr-2 order order-bg-${element.disinfectorId.color}`}>
            <div className="card-body p-0">
              <ul className="font-bold mb-0 list-unstyled">
                <RenderOrderInCalendar order={element} />
              </ul>

              <Link
                to={`/order-full-details/${element._id}`}
                className="btn btn-primary mr-1 mt-2"
              >
                <i className="fas fa-info"></i> Показать
              </Link>

              {/* если заказ полностью закрыт */}
              {orderFullyProcessed(element) ? (
                <React.Fragment>
                  {/* <ClientNotSatisfiedButton order={element} shouldLoadOrder={true} /> */}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* если заказ еще не закрыт */}

                  <Link
                    to={`/edit-order/${element._id}`}
                    className="btn btn-warning mr-1 mt-2"
                  >
                    <i className="fas fa-edit"></i> Редакт.
                  </Link>

                  {/* {this.props.date > new Date().setHours(0, 0, 0, 0) ? (
                    <button className="btn btn-danger mr-1 mt-2" onClick={() => {
                      if (window.confirm('Вы уверены?')) {
                        this.onDeleteOrder(element._id);
                      }
                    }}><i className="fas fa-trash-alt"></i> Удалить</button>
                  ) : ''} */}
                </React.Fragment>
              )}

              <button
                className="btn btn-dark mr-1 mt-2"
                onClick={() => this.duplicateOrder(element)}
              ><i className="far fa-copy"></i> Дубл.</button>

              <ClientNotSatisfiedButton order={element} shouldLoadOrder={true} marginTop={2} />
            </div>
          </div>
        </div>
      ));

      return (
        <div className="hours" key={index}>
          <div className="help row mt-3" id={`hour-${object.hour}`}>
            <button
              to="/create-order"
              className="btn btn-success mr-3"
              onClick={this.onClick.bind(this, object.hour, this.props.subadmin.date)}
            >
              <i className="fas fa-plus"></i>
            </button>

            <h1 className="d-inline mb-0">{`${object.hour}:00`}</h1>
          </div>
          <div className="row">
            {renderOrders.length > 0 ? (renderOrders) : ''}
          </div>
        </div>
      )
    });

    return (
      everythingToRender
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  admin: state.admin,
  subadmin: state.subadmin,
  errors: state.errors,
});

export default connect(mapStateToProps, { getSortedOrders, deleteOrder_v2 })(withRouter(SubadmSortedOrders));