import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';


class RenderOrderInCalendar extends Component {
  render() {
    const { order } = this.props;

    const renderAddress = (
      <React.Fragment>
        {order.address_v2 && Object.keys(order.address_v2).length > 0 ? (
          <li>Адрес: {order.address_v2.region}, район: {order.address_v2.district}, ориентир: {order.address_v2.referencePoint}</li>
        ) : (
          <li>Адрес: {order.address}</li>
        )}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        {order.prevFailedOrder && (
          <React.Fragment>
            <h3><i className="fas fas fa-exclamation"></i> Повторный заказ <i className="fas fas fa-exclamation"></i></h3>
            {order.prevFailedOrder.disinfectorId && order.prevFailedOrder.disinfectorId.name && (
              <li>(Выполнил некач. заказ инспектор {order.prevFailedOrder.disinfectorId.name})</li>
            )}
          </React.Fragment>
        )}

        {order.failed && (
          <h3><i className="fas fas fa-exclamation"></i> Некачественный заказ <i className="fas fas fa-exclamation"></i></h3>
        )}

        {order.adminGaveGrade && (
          <h4>Оценка Админа: {order.adminGrade}</h4>
        )}

        {order.disinfectorId && (
          <li>Инспектор: {order.disinfectorId.name}</li>
        )}

        {order.clientType === 'corporate' && (
          <React.Fragment>
            {order.clientId ? (
              <li>Корп. клиент: {order.clientId.name}, Филиал: {order.filial || '--'}</li>
            ) : <li>Корп. клиент</li>}
          </React.Fragment>
        )}

        {order.clientType === 'individual' && (
          <li>Физ. Клиент: {order.client}</li>
        )}

        <li>Телефон: {order.phone}</li>

        {renderAddress}

        <li>Тип Заказа: {order.typeOfService}</li>
      </React.Fragment>
    )
  }
}

RenderOrderInCalendar.defaultProps = {
  order: {
    disinfectorId: {},
    clientId: {},
    prevFailedOrder: {
      disinfectorId: {},
    },
  },
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(RenderOrderInCalendar));