import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Spinner from '../common/Spinner';
import RenderOrder from '../common/RenderOrder';
import { getOrderHistory } from '../../actions/orderActions';


class OrderHistory extends Component {
  state = {
    initialOrder: {},
    history: [],
  };

  componentDidMount() {
    const object = {
      id: this.props.match.params.id,
    };

    this.props.getOrderHistory(object);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.order.orderHistory && Object.keys(nextProps.order.orderHistory).length > 0) {
      this.setState({
        initialOrder: nextProps.order.orderHistory.initialOrder || {},
        history: nextProps.order.orderHistory.history || [],
      });
    }
  };

  render() {
    return (
      <div className="container-fluid">

        {this.props.order.loading ? <Spinner /> : (
          <>
            <div className="row">
              <div className="col-12 mt-2">
                <h3 className="text-center">Выбранный заказ</h3>
              </div>

              <div className="col-lg-6 col-md-8 mx-auto mt-3">
                <div className="card order">
                  <div className="card-body p-0">
                    <ul className="font-bold list-unstyled">
                      <RenderOrder
                        order={this.state.initialOrder}
                        shouldRenderIfOrderIsPovtor={true}
                        shouldRenderIfOrderIsFailed={false}
                        sholdRenderIfOrderIsReturned={false}
                        shouldRenderDisinfector={false}
                        shouldRenderNextOrdersAfterFailArray={false}
                        shouldRenderPrevFailedOrderDate={false}
                        shouldRenderOperatorDecided={false}
                        shouldRenderAccountantDecided={false}
                        dateRenderMethod={'dateFromOnly'}
                        shouldRenderGuarantee={false}
                        shouldRenderMaterialConsumption={false}
                        shouldRenderPaymentMethod={false}
                        shouldRenderUserAcceptedOrder={false}
                        shouldRenderWhoDealtWithClient={false}
                        shouldRenderUserCreated={true}
                        shouldRenderCompletedAt={false}
                      />
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12 mt-2">
                <h3 className="text-center">История заказ</h3>
              </div>

              {this.state.history.length > 0 ? (
                <>
                  {this.state.history.map((order, index) => (
                    <div className="col-lg-4 col-md-6 mt-3" key={index}>
                      <div className="card order">
                        <div className="card-body p-0">
                          <ul className="font-bold list-unstyled">
                            <RenderOrder
                              order={order}
                              shouldRenderIfOrderIsPovtor={true}
                              shouldRenderIfOrderIsFailed={true}
                              sholdRenderIfOrderIsReturned={false}
                              shouldRenderDisinfector={true}
                              shouldRenderNextOrdersAfterFailArray={false}
                              shouldRenderPrevFailedOrderDate={false}
                              shouldRenderOperatorDecided={true}
                              shouldRenderAccountantDecided={true}
                              dateRenderMethod={'dateFromOnly'}
                              shouldRenderGuarantee={true}
                              shouldRenderMaterialConsumption={true}
                              shouldRenderPaymentMethod={true}
                              shouldRenderUserAcceptedOrder={true}
                              shouldRenderWhoDealtWithClient={true}
                              shouldRenderUserCreated={true}
                              shouldRenderCompletedAt={true}
                              shouldRenderAdminGaveGrade={true}
                            />
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="col-12 mt-2">
                  <h5 className="text-center">Заказов с таким номером телефона больше нет</h5>
                </div>
              )}
            </div>
          </>
        )}

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  order: state.order,
  errors: state.errors,
});

export default connect(mapStateToProps, { getOrderHistory })(withRouter(OrderHistory));