// this function returns true if guarantee period of the completed
// order is expired and false otherwise
export function guaranteeExpired(order = {}) {
  const { completedAt, guarantee_v2 } = order;

  if (!order.guarantee_v2 || order.guarantee_v2.length === 0) {
    return false;
  }

  let passedInDate = new Date(completedAt);

  const guaranteesArray = guarantee_v2.map(item => item.guaranteePeriod);

  // берем наибольшее значение из значений гарантий (в месяцах)
  const maxGuaranteePeriod = Math.max(...guaranteesArray);

  // date of expire
  let expireDate = new Date(passedInDate.setMonth(passedInDate.getMonth() + maxGuaranteePeriod));

  // console.log('func expireDate', expireDate);

  if (new Date().getTime() > expireDate.getTime()) {
    // console.log('func result true');
    return true;
  } else {
    // console.log('func result false');
    return false;
  }
};