import { LS_KEYS } from '../utils/localStorageKeys';


export const getOrdersCacheObject = () => {
  const object = {
    success: false,
    orders: [],
    lastUpdated: new Date(),
  };

  if (!localStorage.getItem(LS_KEYS.ORDERS_IN_CACHE)) {
    return object;
  }

  const cache_object = JSON.parse(localStorage.getItem(LS_KEYS.ORDERS_IN_CACHE));

  if (
    !cache_object || !cache_object.hasOwnProperty('orders') ||
    cache_object['orders'].length === 0
  ) {
    return object;
  }

  object.success = true;
  object.orders = cache_object['orders'];
  object.lastUpdated = cache_object['last_updated'];

  return object;
};