import axios from 'axios';
import { toast } from 'react-toastify';

import {
  GET_ERRORS,
  GET_ALL_DISINFECTORS,
  GET_DISINFECTOR_MATERIALS,
  GET_DISINF_MONTH_STATS,
  GET_DISINF_WEEK_STATS,
  GET_DISINF_DAY_STATS,
  GET_ADD_MATERIAL_EVENTS,
  DISINF_MAT_COMINGS,
  DISINF_MAT_DISTRIBS,
  GET_RETURNED_QUERIES,
  LOADING_DISINF_STATS,
  SET_LOADING_DISINFECTORS,
  LOADING_CURRENT_DISINFECTOR,
  LOADING_ADD_MATERIAL_EVENTS,
} from './types';


// disinfector sees his own stats
export const getDisinfectorStats = (object) => async (dispatch) => {
  dispatch(loadingDisinfStats());

  let dispatch_type = '';

  if (object.type === 'month') {
    dispatch_type = GET_DISINF_MONTH_STATS;
  }

  if (object.type === 'week') {
    dispatch_type = GET_DISINF_WEEK_STATS;
  }

  if (object.type === 'day') {
    dispatch_type = GET_DISINF_DAY_STATS;
  }

  try {
    const res = await axios.post('/stats/disinfector-sees-his-own-stats', { object });

    dispatch({
      type: dispatch_type,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getDisinfectorStats',
      message: 'Не удалось загрузить статистику. Попробуйте еще раз',
    };

    toast.error(error.message);

    const emptyStats = {
      orders: [],
      acceptedOrders: [],
      addedMaterials: [],
    };

    if (dispatch_type && dispatch_type.length > 0) {
      dispatch({
        type: dispatch_type,
        payload: emptyStats,
      });
    }

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const getAllDisinfectorsAndSubadmins = () => async (dispatch) => {
  dispatch(loadingDisinfectors());

  const object = {
    method: 'role',
    roles: ['disinfector', 'subadmin']
  };

  try {
    const res = await axios.post('/get-users', { object });

    dispatch({
      type: GET_ALL_DISINFECTORS,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getAllDisinfectorsAndSubadmins',
      message: 'Не удалось загрузить инспекторов. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_ALL_DISINFECTORS,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const getDisinfectorMaterials = (id) => async (dispatch) => {
  dispatch(loadingCurrentDisinfector());

  try {
    const res = await axios.post('/auth/get-disinfector-materials', { id });

    dispatch({
      type: GET_DISINFECTOR_MATERIALS,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getDisinfectorMaterials',
      message: 'Не удалось загрузить материалы инспектора. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_DISINFECTOR_MATERIALS,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const getAddedMaterialEvents = (id) => async (dispatch) => {
  dispatch(loadingAddMaterialEvents());

  try {
    const res = await axios.post('/order/get-add-material-events', { id });

    dispatch({
      type: GET_ADD_MATERIAL_EVENTS,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getAddedMaterialEvents',
      message: 'Не удалось загрузить раздачи материалов. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_ADD_MATERIAL_EVENTS,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


// disinfector adds material to other user/disinfector
export const disAddMatToOther = (object, occupation, history) => async (dispatch) => {
  try {
    await axios.post('/order/dis-add-mat-to-other-user', { object });

    return history.push(`/${occupation}`);

  } catch (e) {
    const error = {
      info: 'disAddMatToOther',
      message: 'Не удалось раздать материалы. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const getUserMatComing = (object) => async (dispatch) => {
  dispatch(loadingDisinfStats());

  try {
    const res = await axios.post('/stats/get-user-mat-coming', { object });

    dispatch({
      type: DISINF_MAT_COMINGS,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getUserMatComing',
      message: 'Не удалось загрузить приходы материалов. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: DISINF_MAT_COMINGS,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const getUserMatDistrib = (object) => async (dispatch) => {
  dispatch(loadingDisinfStats());

  try {
    const res = await axios.post('/stats/get-user-mat-distrib', { object });

    dispatch({
      type: DISINF_MAT_DISTRIBS,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getUserMatDistrib',
      message: 'Не удалось загрузить раздачи материалов. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: DISINF_MAT_DISTRIBS,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const getReturnedQueries = (id) => async (dispatch) => {
  try {
    const res = await axios.post('/order/get-returned-queries', { id });

    dispatch({
      type: GET_RETURNED_QUERIES,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getReturnedQueries',
      message: 'Не удалось загрузить возвращенные заявки. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_RETURNED_QUERIES,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const notifyUserForIncorrectDataInOrder = (object) => async (dispatch) => {
  try {
    const res = await axios.post('/order/notify-for-incorrect-info', { object });

    toast.success(res.data.message);

  } catch (e) {
    const error = {
      info: 'notifyUserForIncorrectDataInOrder',
      message: 'Не удалось уведомить о неправильной дате в заявке. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};



export const loadingDisinfStats = () => {
  return {
    type: LOADING_DISINF_STATS
  };
}

export const loadingDisinfectors = () => {
  return {
    type: SET_LOADING_DISINFECTORS
  };
}

export const loadingCurrentDisinfector = () => {
  return {
    type: LOADING_CURRENT_DISINFECTOR
  };
}


export const loadingAddMaterialEvents = () => {
  return {
    type: LOADING_ADD_MATERIAL_EVENTS
  };
}