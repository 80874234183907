const orderTypes = [
  { label: 'Пест контроль (контроль вредителей)', value: 'Пест контроль (контроль вредителей)' },
  { label: 'Дезинсекция - комплекс насекомых', value: 'Дезинсекция - комплекс насекомых' },
  { label: 'Дезинфекция - от инфекций', value: 'Дезинфекция - от инфекций' },
  { label: 'Клопа', value: 'Клопа' },
  { label: 'Таракан', value: 'Таракан' },
  { label: 'Сичкон', value: 'Сичкон', translation: 'Мышь' },
  { label: 'Каламуш', value: 'Каламуш', translation: 'Крыса' },
  { label: 'Пашша', value: 'Пашша', translation: 'Муха' },
  { label: 'Чивин', value: 'Чивин', translation: 'Комар' },
  { label: 'Чумоли', value: 'Чумоли', translation: 'Муравьи' },
  { label: 'Бурга', value: 'Бурга', translation: 'Блоха' },
  { label: 'Калтакесак', value: 'Калтакесак', translation: 'Ящерица' },
  { label: 'Скорпион', value: 'Скорпион' },
  { label: 'Клещи', value: 'Клещи' },
  { label: 'Илон', value: 'Илон', translation: 'Змея' },
  { label: 'Шиллик курт', value: 'Шиллик курт', translation: 'Cлизистый Червь' },
  { label: 'Термит', value: 'Термит' },
  { label: 'Ок пашша', value: 'Ок пашша', translation: 'Белая муха' },
  { label: 'Курт', value: 'Курт', translation: 'Червь' },
  { label: 'Плесень', value: 'Плесень' },
  { label: 'Профилактика', value: 'Профилактика' },
  { label: 'Дезодорация - от запаха', value: 'Дезодорация - от запаха' },
  { label: 'Фумигация - газация', value: 'Фумигация - газация' },
  { label: 'Осмотр и консультации', value: 'Осмотр и консультации' },
];

export default orderTypes;