const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

// this function return difference between 2 dates in days
// function takes in 2 Dates and returns a number
export const getDateDifferenceInDays = (date1, date2) => {
  const diffTime = Math.abs(date2 - date1);

  // in stackoverflow, "ceil" function was used, instead of "floor"
  const diffDays = Math.floor(diffTime / MILLISECONDS_IN_DAY);

  return diffDays;
};