import axios from 'axios';
import { toast } from 'react-toastify';
import {
  GET_ERRORS,
  GET_ACC_QUERIES,
  GET_ACC_QUERY_BY_ID,
  GET_ACC_STATS,
  DELETE_QUERY_FROM_STATE,
  SET_TIME_PERIOD,
  LOADING_ACC_QUERIES,
  ACC_CLEAR_STATS,
  SET_LOADING_ACC_STATS
} from './types';


// get order queries for accountant to confirm (only corporate clients)
export const getAccountantQueries = (object) => async (dispatch) => {
  dispatch(setTimePeriod(object));
  dispatch(loadingQueries());

  try {
    const res = await axios.post('/accountant/get-queries', { object });

    dispatch({
      type: GET_ACC_QUERIES,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getAccountantQueries',
      message: 'Не удалось получить заявки. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_ACC_QUERIES,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


// accountant gets complete order by id to fill the confirmation form
export const getCompleteOrderById = (id) => async (dispatch) => {
  dispatch(loadingQueries());

  try {
    const res = await axios.post('/accountant/get-query-by-id', { id });

    dispatch({
      type: GET_ACC_QUERY_BY_ID,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getCompleteOrderById',
      message: 'Не удалось получить заявку. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


// accountant confirms or rejects completed order
export const accountantConfirmQuery = (object, history) => async (dispatch) => {
  // delete query from global state because the query should not stay in queries page
  dispatch(deleteQuery(object.orderId));

  try {
    await axios.post('/accountant/confirm-query', { object });

    return history.push('/accountant/queries');

  } catch (e) {
    const error = {
      info: 'accountantConfirmQuery',
      message: 'Не удалось выполнить операцию над заявкой. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


// accountant sees statistics
export const getAccStats = (object) => async (dispatch) => {
  dispatch(setLoadingStats());

  try {
    const res = await axios.post('/accountant/get-stats', { object });

    dispatch({
      type: GET_ACC_STATS,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getAccStats',
      message: 'Не удалось получить статистику. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_ACC_STATS,
      payload: {
        orders: [],
        method: object.type,
      },
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


// export const searchContracts = (object) => (dispatch) => {
//   dispatch(loadingQueries());
//   axios.post('/accountant/search-contracts', { object: object })
//     .then(res =>
//       dispatch({
//         type: SEARCH_CONTRACTS,
//         payload: res.data
//       })
//     )
//     .catch(err =>
//       dispatch({
//         type: GET_ERRORS,
//         payload: err
//       })
//     );
// };


// delete query from global state
export const deleteQuery = (id) => {
  return {
    type: DELETE_QUERY_FROM_STATE,
    payload: id
  }
}


// set time to preserve time data in global state
export const setTimePeriod = (object) => {
  return {
    type: SET_TIME_PERIOD,
    payload: object
  }
}


export const clearStats = () => {
  return {
    type: ACC_CLEAR_STATS
  }
};


// Loadings
export const loadingQueries = () => {
  return {
    type: LOADING_ACC_QUERIES
  };
}


// loading stats
export const setLoadingStats = () => {
  return {
    type: SET_LOADING_ACC_STATS
  };
}