import axios from 'axios';
import { toast } from 'react-toastify';
import { getDateDifferenceInDays } from '../utils/dateDifference';
import { getOrdersCacheObject } from '../utils/getOrdersFromCache';
import { LS_KEYS } from '../utils/localStorageKeys';

import {
  GET_ERRORS,
  GET_SORTED_ORDERS,
  GET_NOT_COMPLETED_ORDERS,
  GET_COMPLETE_ORDERS,
  SET_COMPLETE_ORDERS_TIME_PARAMS,
  GET_COMPLETE_ORDER_BY_ID,
  DELETE_QUERY_FROM_STATE,
  GOT_STATS_FOR_OPERATOR,
  GET_REPEAT_ORDERS,
  SET_REPEAT_ORDER_SEARCH_VARS,
  SET_LOADING_SORTED_ORDERS,
  SET_LOADING_COMPLETE_ORDERS,
  SET_LOADING_OPERATOR_STATS,
  SET_DATE_IN_CALENDAR,
} from './types';


export const getSortedOrders = (date) => async (dispatch) => {
  dispatch(setLoadingSortedOrders());

  const cacheResponse = getOrdersCacheObject();

  try {
    const res = await axios.post('/operator/get-sorted-orders', { date });

    dispatch({
      type: GET_SORTED_ORDERS,
      payload: res.data,
      date: date,
    });

    dispatch({ type: SET_DATE_IN_CALENDAR, payload: date });

    // если кэш записывался более 3 дней назад, то заново получить заказы за +- неделю и записать в кэш
    if (
      !cacheResponse.success ||
      getDateDifferenceInDays(new Date(), new Date(cacheResponse.lastUpdated)) > 3
    ) {

      // console.log('renewing cache');
      // получить заказы +неделя и -неделя от date
      const helper_object = {
        period: 'week',
      };

      const res2 = await axios.post('/order/get-orders-within-period', { object: helper_object });

      const new_cache_object = {
        orders: res2.data.data || [],
        last_updated: new Date(),
      };

      // console.log('res2', res2.data);
      localStorage.setItem(LS_KEYS.ORDERS_IN_CACHE, JSON.stringify(new_cache_object));
    }

  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err, });

    // we have to show orders that belong to date
    // check if 2 dates are the same day
    const orders_in_date = cacheResponse.orders.filter(order => new Date(order.dateFrom).toDateString() === new Date(date).toDateString());

    if (cacheResponse.success) {
      dispatch({
        type: GET_SORTED_ORDERS,
        payload: orders_in_date,
        date: date,
      });

      // close previous toasts
      toast.dismiss();
      toast.warning('Данные получены из кэша');
    }
  }
};


// operator gets complete orders for confirmation
export const getCompleteOrders = (object) => async (dispatch) => {
  dispatch(setLoadingCompleteOrders());

  dispatch({
    type: SET_COMPLETE_ORDERS_TIME_PARAMS,
    payload: object,
  });

  try {
    const res = await axios.post('/operator/get-complete-orders', { object });

    dispatch({ type: GET_COMPLETE_ORDERS, payload: res.data, });

  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err, });
  }
};


export const getCompleteOrderById = (id) => (dispatch) => {
  dispatch(setLoadingCompleteOrders());
  axios.post(`/operator/get-complete-order-by-id/${id}`)
    .then(res =>
      dispatch({
        type: GET_COMPLETE_ORDER_BY_ID,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


// operator confirms completed order
export const confirmCompleteOrder = (object, history) => (dispatch) => {
  // delete query from global state because the query should not stay in queries page
  dispatch({
    type: DELETE_QUERY_FROM_STATE,
    payload: object.orderId
  });

  axios.post('/operator/confirm-complete-order', { object: object })
    .then(() => history.push('/operator/order-queries'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};


// get stats for operator
export const getOperatorStats = (month, year) => (dispatch) => {
  dispatch(setLoadingStats());
  axios.post('/stats/for-operator', { month: month, year: year })
    .then(res =>
      dispatch({
        type: GOT_STATS_FOR_OPERATOR,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};


export const getRepeatOrders = (object) => (dispatch) => {
  dispatch(setLoadingSortedOrders());

  // set repeat order search Vars
  dispatch({
    type: SET_REPEAT_ORDER_SEARCH_VARS,
    payload: {
      method: object.type,
      headingDay: object.day || '',
      selectedDays: object.days || []
    }
  });

  axios.post('/operator/get-repeat-orders', { object })
    .then(res =>
      dispatch({
        type: GET_REPEAT_ORDERS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};


export const repeatOrderNotNeeded = (id, history, occupation) => (dispatch) => {
  axios.post('/operator/repeat-order-not-needed', { id: id })
    .then(() => history.push(`/${occupation}`))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};


// operator sees his own statistics
export const getStatsForOperator = (object) => (dispatch) => {
  dispatch(setLoadingStats());
  axios.post('/operator/get-operator-stats', { object: object })
    .then(res =>
      dispatch({
        type: GOT_STATS_FOR_OPERATOR,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};


export const getNotCompOrders = () => async (dispatch) => {
  dispatch(setLoadingSortedOrders());

  try {
    const res = await axios.post('/operator/get-not-comp-orders');

    dispatch({
      type: GET_NOT_COMPLETED_ORDERS,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getNotCompOrders',
      message: 'Не удалось получить невыполненные заказы. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_NOT_COMPLETED_ORDERS,
      payload: [],
    });

    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};


// Loading sorted orders
export const setLoadingSortedOrders = () => {
  return {
    type: SET_LOADING_SORTED_ORDERS
  };
};


// Loading complete orders
export const setLoadingCompleteOrders = () => {
  return {
    type: SET_LOADING_COMPLETE_ORDERS
  };
};


// loading stats
export const setLoadingStats = () => {
  return {
    type: SET_LOADING_OPERATOR_STATS
  };
}