const advertisements = [
  { label: 'Google', value: 'Google' },
  { label: 'Yandex', value: 'Yandex' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'Olx.uz', value: 'Olx.uz' },
  { label: 'Баннеры', value: 'Баннеры' },
  { label: 'Машина баннер', value: 'Машина баннер' },
  { label: 'Рекомендации (соседи, друзья,  родственники, партнеры)', value: 'Рекомендации' },
  { label: 'SMS', value: 'SMS' },
  { label: 'Флаеры', value: 'Флаеры' }
];

module.exports = advertisements;