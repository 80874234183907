import axios from 'axios';
import { toast } from 'react-toastify';
import { getDateDifferenceInDays } from '../utils/dateDifference';
import { getOrdersCacheObject } from '../utils/getOrdersFromCache';
import { LS_KEYS } from '../utils/localStorageKeys';

import {
  GET_ERRORS,
  SUBADM_GET_MY_ORDERS,
  GET_SORTED_ORDERS_SUBADMIN,
  SET_DATE_IN_CALENDAR,
  ALL_DISINFECTORS,
  SUBADMIN_MATERIALS,
  SUBADMIN_ADDS_MATERIAL,
  SUBADMIN_MAT_COM_HISTORY,
  SUBADMIN_MAT_DISTRIB_HISTORY,
  LOADING_SORTED_ORDERS_SUBADMIN,
  SUBADMIN_LOADING,
  SUBADMIN_LOADING_STATS,
} from './types';


// get orders that subadmin should complete
export const getSubadmOrders = (id) => (dispatch) => {
  dispatch(setSubadminLoading());

  // 21.04.2021 now subadmin and disinfector refer to the same endpoint 
  // to get orders they should complete
  axios.post('/order/get-my-orders', { userId: id })
    .then(res =>
      dispatch({
        type: SUBADM_GET_MY_ORDERS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};


export const getSortedOrders = (date) => async (dispatch) => {
  dispatch(setLoadingSortedOrders());

  const cacheResponse = getOrdersCacheObject();

  try {
    const res = await axios.post('/subadmin/get-sorted-orders', { date });

    dispatch({
      type: GET_SORTED_ORDERS_SUBADMIN,
      payload: res.data,
      date: date,
    });

    dispatch({ type: SET_DATE_IN_CALENDAR, payload: date });

    // если кэш записывался более 3 дней назад, то заново получить заказы за +- неделю и записать в кэш
    if (
      !cacheResponse.success ||
      getDateDifferenceInDays(new Date(), new Date(cacheResponse.lastUpdated)) > 3
    ) {

      // console.log('renewing cache');
      // получить заказы +неделя и -неделя от date
      const helper_object = {
        period: 'week',
      };

      const res2 = await axios.post('/order/get-orders-within-period', { object: helper_object });

      const new_cache_object = {
        orders: res2.data.data || [],
        last_updated: new Date(),
      };

      // console.log('res2', res2.data);
      localStorage.setItem(LS_KEYS.ORDERS_IN_CACHE, JSON.stringify(new_cache_object));
    }

  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err, });

    // we have to show orders that belong to date
    // check if 2 dates are the same day
    const orders_in_date = cacheResponse.orders.filter(order => new Date(order.dateFrom).toDateString() === new Date(date).toDateString());

    if (cacheResponse.success) {
      dispatch({
        type: GET_SORTED_ORDERS_SUBADMIN,
        payload: orders_in_date,
        date: date,
      });

      // close previous toasts
      toast.dismiss();
      toast.warning('Данные получены из кэша');
    }
  }
};


export const getAllDisinfectors = () => (dispatch) => {
  dispatch(setSubadminLoading());

  const object = {
    method: 'role',
    roles: ['disinfector']
  };

  // axios.post('/subadmin/get-all-disinfectors')
  axios.post('/get-users', { object })
    .then(res =>
      dispatch({
        type: ALL_DISINFECTORS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};


export const getSubadminMaterials = (id) => (dispatch) => {
  axios.post('/subadmin/get-subadmin-materials', { id: id })
    .then(res =>
      dispatch({
        type: SUBADMIN_MATERIALS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};


// subadmin adds material to disinfector
export const addMaterialToDisinfector = (object, history) => (dispatch) => {
  axios.post('/subadmin/add-material-to-disinfector', { object: object })
    .then(res => {
      dispatch({
        type: SUBADMIN_ADDS_MATERIAL,
        payload: res.data
      });
      return history.push('/subadmin');
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};


// subadmin sees how much material he received from admin
export const getMatComHistory = (object) => (dispatch) => {
  dispatch(loadingStats());
  axios.post('/subadmin/get-material-coming-history', { object: object })
    .then(res =>
      dispatch({
        type: SUBADMIN_MAT_COM_HISTORY,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};


// subadmin sees materials he distributed to disinfectors
export const getSubMatDistrib = (object) => (dispatch) => {
  dispatch(loadingStats());
  axios.post('/subadmin/get-material-distrib-history', { object: object })
    .then(res =>
      dispatch({
        type: SUBADMIN_MAT_DISTRIB_HISTORY,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};


// Loading sorted orders
export const setLoadingSortedOrders = () => {
  return {
    type: LOADING_SORTED_ORDERS_SUBADMIN
  };
};


// 
export const setSubadminLoading = () => {
  return {
    type: SUBADMIN_LOADING
  };
}


export const loadingStats = () => {
  return {
    type: SUBADMIN_LOADING_STATS
  };
}