export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CORPORATE_CLIENTS = 'CORPORATE_CLIENTS';
export const GET_DISINFECTORS = 'GET_DISINFECTORS';
export const SET_LOADING = 'SET_LOADING';

export const GET_ALL_ORDERS = 'GET_ALL_ORDERS';
export const ADD_ORDER = 'ADD_ORDER';
export const GET_COMPLETE_ORDERS_IN_MONTH = 'GET_COMPLETE_ORDERS_IN_MONTH';
export const SET_COMP_ORDER_METHOD_INPUT = 'SET_COMP_ORDER_METHOD_INPUT';
export const GET_ALL_USERS = 'GET_ALL_USERS';


// disinfector
export const GET_DISINFECTOR_MATERIALS = 'GET_DISINFECTOR_MATERIALS';
export const GET_DISINF_MONTH_STATS = 'GET_DISINF_MONTH_STATS';
export const GET_DISINF_WEEK_STATS = 'GET_DISINF_WEEK_STATS';
export const GET_DISINF_DAY_STATS = 'GET_DISINF_DAY_STATS';
export const LOADING_DISINF_STATS = 'LOADING_DISINF_STATS';
export const LOADING_CURRENT_DISINFECTOR = 'LOADING_CURRENT_DISINFECTOR';
export const LOADING_ADD_MATERIAL_EVENTS = 'LOADING_ADD_MATERIAL_EVENTS';
export const GET_ADD_MATERIAL_EVENTS = 'GET_ADD_MATERIAL_EVENTS';
export const GET_ALL_DISINFECTORS = 'GET_ALL_DISINFECTORS';
export const DISINF_MAT_COMINGS = 'DISINF_MAT_COMINGS';
export const DISINF_MAT_DISTRIBS = 'DISINF_MAT_DISTRIBS';
export const GET_RETURNED_QUERIES = 'GET_RETURNED_QUERIES';
export const CLEAR_ORDER_BY_ID = 'CLEAR_ORDER_BY_ID';

// operator
export const GET_SORTED_ORDERS = 'GET_SORTED_ORDERS';
export const GET_NOT_COMPLETED_ORDERS = 'GET_NOT_COMPLETED_ORDERS';
export const SET_LOADING_SORTED_ORDERS = 'SET_LOADING_SORTED_ORDERS';
export const SET_LOADING_COMPLETE_ORDERS = 'SET_LOADING_COMPLETE_ORDERS';
export const SET_LOADING_REPEAT_ORDER = 'SET_LOADING_REPEAT_ORDER';
export const GET_ORDER_BY_ID = 'GET_ORDER_BY_ID';
export const GET_COMPLETE_ORDERS = "GET_COMPLETE_ORDERS";
export const SET_COMPLETE_ORDERS_TIME_PARAMS = 'SET_COMPLETE_ORDERS_TIME_PARAMS';
export const GET_COMPLETE_ORDER_BY_ID = 'GET_COMPLETE_ORDER_BY_ID';
export const SET_LOADING_OPERATOR_STATS = 'SET_LOADING_OPERATOR_STATS';
export const GOT_STATS_FOR_OPERATOR = 'GOT_STATS_FOR_OPERATOR';
export const GET_REPEAT_ORDERS = 'GET_REPEAT_ORDERS';
export const SET_REPEAT_ORDER_SEARCH_VARS = 'SET_REPEAT_ORDER_SEARCH_VARS';
export const GET_REPEAT_ORDER_FORM = 'GET_REPEAT_ORDER_FORM';
export const SEARCH_ORDERS = 'SEARCH_ORDERS';
export const GET_ORDER_HISTORY = 'GET_ORDER_HISTORY';
export const SET_SEARCH_ORDER_METHOD = 'SET_SEARCH_ORDER_METHOD';


// ADMIN
export const SET_LOADING_ORDER_QUERIES_FOR_ADMIN = 'SET_LOADING_ORDER_QUERIES_FOR_ADMIN';
export const SET_LOADING_ADMIN_STATS = 'SET_LOADING_ADMIN_STATS';
export const SET_LOADING_DISINFECTORS = 'SET_LOADING_DISINFECTORS';
export const SET_LOADING_OPERATORS = 'SET_LOADING_OPERATORS';
export const LOADING_CLIENTS = 'LOADING_CLIENTS';
export const SET_LOADING_ADD_MATERIAL_EVENTS = 'SET_LOADING_ADD_MATERIAL_EVENTS';
export const CLEAR_STATS_DATA_ADMIN = 'CLEAR_STATS_DATA_ADMIN';

export const GET_ORDER_QUERIES_FOR_ADMIN = 'GET_ORDER_QUERIES_FOR_ADMIN';
export const GET_ADMIN_MONTH_STATS = 'GET_ADMIN_MONTH_STATS';
export const GET_ADMIN_WEEK_STATS = 'GET_ADMIN_WEEK_STATS';
export const GET_ADMIN_DAY_STATS = 'GET_ADMIN_DAY_STATS';
export const GET_ADV_STATS = 'GET_ADV_STATS';
export const GET_OPERATOR_STATS = 'GET_OPERATOR_STATS';
export const GET_ALL_DISINFECTORS_FOR_ADMIN = 'GET_ALL_DISINFECTORS_FOR_ADMIN';
export const GET_ALL_OPERATORS_FOR_ADMIN = 'GET_ALL_OPERATORS_FOR_ADMIN';
export const GET_ALL_OPERATORS_AND_ADMINS_FOR_ADMIN = 'GET_ALL_OPERATORS_AND_ADMINS_FOR_ADMIN';
export const GET_ADD_MAT_EVENTS_MONTH = 'GET_ADD_MAT_EVENTS_MONTH';
export const GET_ADD_MAT_EVENTS_WEEK = 'GET_ADD_MAT_EVENTS_WEEK';
export const ADD_MAT_DISINFECTOR = 'ADD_MAT_DISINFECTOR';
export const DISINF_STATS_MONTH_ADMIN = 'DISINF_STATS_MONTH_ADMIN';
export const DISINF_STATS_MONTH_WEEK = 'DISINF_STATS_MONTH_WEEK';
export const DISINF_STATS_DAY_ADMIN = 'DISINF_STATS_DAY_ADMIN';
export const GET_CURR_MAT_ADMIN = 'GET_CURR_MAT_ADMIN';
export const LOADING_CUR_MAT = 'LOADING_CUR_MAT';
export const UPDATE_MAT_COMING = 'UPDATE_MAT_COMING';
export const MAT_COMING_MONTH = 'MAT_COMING_MONTH';
export const MAT_COMING_WEEK = 'MAT_COMING_WEEK';
export const SEARCH_CLIENTS = 'SEARCH_CLIENTS';
export const CLIENT_BY_ID = 'CLIENT_BY_ID';
export const SET_ORDERS_OF_CLIENT = 'SET_ORDERS_OF_CLIENT';
export const SET_LOADING_CLIENT_ORDERS = 'SET_LOADING_CLIENT_ORDERS';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';

export const LOADING_SORTED_ORDERS_ADMIN = 'LOADING_SORTED_ORDERS_ADMIN';
export const GET_SORTED_ORDERS_ADMIN = 'GET_SORTED_ORDERS_ADMIN';
export const REMOVE_DISABLED_USER_FROM_DOM = 'REMOVE_DISABLED_USER_FROM_DOM';
export const UPDATE_GRADE_OF_ORDER = 'UPDATE_GRADE_OF_ORDER';
export const SET_ADDRESS_AUTOCOMPLETE = 'SET_ADDRESS_AUTOCOMPLETE';


// Subadmin
export const SUBADM_GET_MY_ORDERS = 'SUBADM_GET_MY_ORDERS';
export const GET_SORTED_ORDERS_SUBADMIN = 'GET_SORTED_ORDERS_SUBADMIN';
export const LOADING_SORTED_ORDERS_SUBADMIN = 'LOADING_SORTED_ORDERS_SUBADMIN';
export const SUBADMIN_LOADING = 'SUBADMIN_LOADING';
export const ALL_DISINFECTORS = 'ALL_DISINFECTORS';
export const SUBADMIN_MATERIALS = 'SUBADMIN_MATERIALS';
export const SUBADMIN_ADDS_MATERIAL = 'SUBADMIN_ADDS_MATERIAL';
export const SUBADMIN_LOADING_STATS = 'SUBADMIN_LOADING_STATS';
export const SUBADMIN_MAT_COM_HISTORY = 'SUBADMIN_MAT_COM_HISTORY';
export const SUBADMIN_MAT_DISTRIB_HISTORY = 'SUBADMIN_MAT_DISTRIB_HISTORY';


// accountant
export const GET_ACC_QUERIES = 'GET_ACC_QUERIES';
export const GET_ACC_QUERY_BY_ID = 'GET_ACC_QUERY_BY_ID';
export const GET_ACC_STATS = 'GET_ACC_STATS';
export const DELETE_QUERY_FROM_STATE = 'DELETE_QUERY_FROM_STATE';
export const SET_TIME_PERIOD = 'SET_TIME_PERIOD';
export const SEARCH_CONTRACTS = 'SEARCH_CONTRACTS';
export const LOADING_ACC_QUERIES = 'LOADING_ACC_QUERIES';
export const ACC_CLEAR_STATS = 'ACC_CLEAR_STATS';
export const SET_LOADING_ACC_STATS = 'SET_LOADING_ACC_STATS';


// Fail order
export const SET_FAILED_ORDER = 'SET_FAILED_ORDER';
export const SET_LOADING_FAILED_ORDER = 'SET_LOADING_FAILED_ORDER';
export const GET_FAILED_ORDERS = 'GET_FAILED_ORDERS';
export const SET_FAIL_SEARCH_VARS = 'SET_FAIL_SEARCH_VARS';


// OPTIONS
export const SET_THEME = 'SET_THEME';
export const SET_DATE_IN_CALENDAR = 'SET_DATE_IN_CALENDAR';
export const BACK_BUTTON_ENABLED = 'BACK_BUTTON_ENABLED';


// Material
export const GET_ALL_MATERIALS = 'GET_ALL_MATERIALS';
export const LOADING_ALL_MATERIALS = 'LOADING_ALL_MATERIALS';